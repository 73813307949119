import * as React from 'react';
import Head from 'next/head';
import '../styles/index.css';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from 'styles/theme';
import Router from 'next/router';
import { Helmet } from 'react-helmet';
import NProgress from 'nprogress';
import { ToastProvider } from 'react-toast-notifications';

import withGA from 'next-ga';
import { useApollo } from 'lib/initApollo';

import { ApolloProvider } from '@apollo/client';

import * as Sentry from '@sentry/node';
import { RewriteFrames } from '@sentry/integrations';
import getConfig from 'next/config';
import { AppContext, AppProps } from 'next/app';
import { Layout } from 'components/Layout';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename?.replace(distDir, 'app:///_next');
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

Router.events.on('routeChangeStart', (url: string) => {
  console.log('Navigating to: ', url);
  NProgress.start();
});
Router.events.on('routeChangeComplete', (url: string) => {
  console.log('Completed navigation to: ', url);
  NProgress.done();
});
Router.events.on('routeChangeError', (url: string) => {
  console.log('error navigation to: ', url);
  NProgress.done();
});

type Props = {
  err: any;
} & AppProps;

const MyApp = ({ Component, router, pageProps, err }: Props) => {
  const apolloClient = useApollo(pageProps.initialApolloState);
  return (
    <>
      <Head>
        <title>DeLMO</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no" />
      </Head>
      <ThemeProvider theme={theme}>
        <ToastProvider autoDismissTimeout={4000}>
          <ApolloProvider client={apolloClient}>
            <CssBaseline />
            {/*<Header auth={pageProps.auth} userName={pageProps.userName} />*/}
            <Helmet>
              <html />
            </Helmet>
            <Layout>
              <Component {...pageProps} router={router} err={err} />
            </Layout>
          </ApolloProvider>
        </ToastProvider>
      </ThemeProvider>
    </>
  );
};

MyApp.getInitialProps = async ({ Component, ctx }: AppContext) => {
  let pageProps = {};

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  Object.assign(pageProps, {
    auth: true,
    userName: 'not found',
  });
  return {
    pageProps,
  };
};

export default withGA('UA-151504325-2', Router)(MyApp);
