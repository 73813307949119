import React from 'react';
import Link from 'next/link';
import { HeaderAppBarFragment } from '../../generated/graphql';

type Props = {
  currentUser?: HeaderAppBarFragment | null;
};

interface HTMLElementEvent<T extends HTMLElement> extends Event {
  target: T;
}

export const HeaderAppBar: React.FC<Props> = ({ currentUser }) => {
  const [open, toggleOpen] = React.useState(false);
  const menuRef = React.useRef<HTMLDivElement>(null);
  const documentClickHandler = React.useRef<any>();

  React.useEffect(() => {
    documentClickHandler.current = (e: HTMLElementEvent<HTMLElement>) => {
      if (menuRef && menuRef.current && !menuRef.current.contains(e.target)) {
        // ref内にクリックされたeventのDOMが含まれているかを確認する
        toggleOpen(false);
        removeDocumentClickHandler();
      }
    };
  }, []);

  const removeDocumentClickHandler = () => {
    document.removeEventListener('click', documentClickHandler.current);
  };

  const handleOpen = () => {
    if (open) return;
    toggleOpen(true);
    document.addEventListener('click', documentClickHandler.current);
  };

  return (
    <>
      <nav className="bg-d-lightGray">
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between h-12">
            <div className="flex-1 flex items-stretch justify-start ">
              <div className="flex-shrink-0 flex items-center">
                <Link href="/video/category">
                  <a>
                    <img className="h-6 w-auto" src={'/static/trimed_advertiser_logo.png'} />
                  </a>
                </Link>
              </div>
              <div className="hidden sm:block sm:ml-6">
                <div className="flex">
                  <Link href="/video/category">
                    <a className="px-3 py-2 rounded-md text-sm leading-5 text-d-black hover:font-bold focus:outline-none focus:font-bold">
                      カテゴリー一覧
                    </a>
                  </Link>
                  <Link href="/actors">
                    <a className="ml-4 px-3 py-2 rounded-md text-sm leading-5 text-d-black hover:font-bold focus:outline-none focus:font-bold">
                      モデル一覧
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            {currentUser && (
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <div className="ml-3 relative">
                  <div>
                    <button
                      className="flex text-sm rounded-full focus:outline-none focus:border-white transition duration-150 ease-in-out"
                      id="user-menu"
                      aria-label="User menu"
                      aria-haspopup="true"
                      onClick={handleOpen}
                    >
                      <img
                        className="h-6 w-6 rounded-full"
                        src={
                          currentUser.profileImageURL === ''
                            ? '/static/perm_identity_black_144x144.png'
                            : currentUser.profileImageURL
                        }
                      />
                    </button>
                  </div>
                  <div
                    ref={menuRef}
                    className={`bg-white origin-top-right absolute right-0 mt-2 w-24 rounded-md shadow-lg ${
                      open ? 'block' : 'hidden'
                    }`}
                  >
                    <div
                      className="py-1 rounded-md bg-white shadow-xs"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu"
                    >
                      <a
                        href="/api/logout"
                        className="block px-4 py-2 text-sm leading-5 text-d-black hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                        role="menuitem"
                      >
                        ログアウト
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};
