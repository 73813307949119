import React from 'react';
import { useGetCurrentUserQuery } from '../../generated/graphql';
import { HeaderAppBar } from '../HeaderAppBar';

export const Layout: React.FC = ({ children }) => {
  const { loading, error, data } = useGetCurrentUserQuery();
  if (error) {
    return <div>`Error! ${error.message}`</div>;
  }
  return (
    <>
      <HeaderAppBar currentUser={data?.currentUser} />
      {loading ? <div role="progressbar">loading</div> : <>{children}</>}
    </>
  );
};
