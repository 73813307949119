import { createMuiTheme } from '@material-ui/core/styles';
import { green, blue } from '@material-ui/core/colors';

// A theme with custom primary and secondary color.
// It's optional.

const theme = createMuiTheme({
  palette: {
    primary: {
      light: blue[300],
      main: blue[500],
      dark: blue[700],
    },
    secondary: {
      light: green[300],
      main: green[500],
      dark: green[700],
    },
    default: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
    },
    typography: {
      useNextVariants: true,
    },
    background: {
      default: '#ffffff',
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any);
export type Theme = typeof theme;
export default theme;
