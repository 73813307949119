import * as React from 'react';
import { ApolloClient, InMemoryCache, NormalizedCacheObject, HttpLink, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import fetch from 'isomorphic-unfetch';
import { isBrowser } from './isBrowser';
import Router from 'next/router';

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

// Polyfill fetch() on the server (used by apollo-client)
if (!isBrowser) {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  (global as any).fetch = fetch;
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
function createApolloClient() {
  const httpLink = new HttpLink({
    uri: '/api/graphql',
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ extensions }) => {
        if (typeof extensions === 'undefined') {
          return;
        }
        // 認証エラーの場合は強制的にLogin画面にリダイレクト
        if (extensions.code === 'AUTHENTICATION_ERROR') {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((process as any).browser) {
            Router.push('/api/login');
          }
        }
      });
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      if (networkError['statusCode'] === 401) {
        if ((process as any).browser) {
          Router.push('/api/login');
        }
      }
    }
  });

  const link = ApolloLink.from([errorLink, httpLink]);

  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  return new ApolloClient({
    connectToDevTools: isBrowser,
    ssrMode: false,
    link: link,
    cache: new InMemoryCache(),
  });
}

export function initializeApollo(initialState: any = null) {
  const _apolloClient = apolloClient != null ? apolloClient : createApolloClient();
  // TODO TypeScript3.7以上であれば書き換えられる const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState: any) {
  const store = React.useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
